import { createStore, persist } from "easy-peasy";
import userModel from "./models/users";
import localConfigModel from "./models/config";
import {billboardModel} from "./models/billboards"
import bookingModel from "./models/bookings";

const storeModel = {
  localConfig: localConfigModel,
  auth: userModel,
  billboards: billboardModel,
  bookings: bookingModel,
};

const appStore = createStore(persist(storeModel, {
    storage: "localStorage",
  }));

export default appStore;
