import { appRoutes } from "./routes";

const protectedRoutes = [
    appRoutes.profile,
    appRoutes.panier,
    appRoutes.checkout,

    appRoutes.homeBackend,
    appRoutes.listBillboard,
    appRoutes.createBillboard,
    appRoutes.editBillboard,
    appRoutes.bookingBillboard,

    appRoutes.homeAdmin,
    appRoutes.listBillboardAdmin,
    appRoutes.allBookings


];
export default protectedRoutes;
