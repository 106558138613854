import "../styles/globals.scss";

import NextNprogress from "nextjs-progressbar";
import { AnimatePresence } from "framer-motion";
import { StoreProvider } from "easy-peasy";

import ProtectRoute from "../components/auth/protect-route";
import appStore from "../store";
import DetectOffline from "../components/DetectOffline";

import * as ga from "../utils/ga";
import { useEffect } from "react";

function RegitorrApp({ Component, pageProps, router }) {
  useEffect(() => {
    //Google analytics stuffs
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <AnimatePresence exitBeforeEnter>
      <StoreProvider store={appStore}>
        <DetectOffline />
        <ProtectRoute>
          <NextNprogress color="#0180ff" />
          <Component {...pageProps} key={router.route} />
        </ProtectRoute>
      </StoreProvider>
    </AnimatePresence>
  );
}
export default RegitorrApp;
