import React from "react";
import { ToastContainer, toast } from "react-toastify";
import Offline from "react-offline";
import { useStoreActions, useStoreState } from "easy-peasy";

const DetectOffline = () => {
  const { setNetworkStatus, setOfflineAlertShowed } = useStoreActions(
    (state) => state.localConfig
  );
  const { isOfflineAlertShowed } = useStoreState((state) => state.localConfig);

  const toastsIds = {
    offline: "offline-errro-toastId",
    online: "oneline-errro-toastId",
  };

  let timeout = 0;
  const handleInternetStatusChange = (isOffline) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setNetworkStatus(isOffline);

      if (isOffline) {
        toast.dismiss(toastsIds.online);
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>Il semble que vous êtes hors-ligne</strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: toastsIds.offline,
          }
        );
        setOfflineAlertShowed(true);
      } else if (!isOffline && isOfflineAlertShowed) {
        toast.dismiss(toastsIds.offline);
        toast.info(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>Connexion retablie avec succès</strong>
            </p>
          ),
          {
            toastId: toastsIds.online,
            delay: 600,
          }
        );
        setOfflineAlertShowed(false);
      }
    }, 1000);
  };

  return (
    <>
      {typeof window !== "undefined" && (
        <Offline
          onChange={({ isOffline }) => handleInternetStatusChange(isOffline)}
        />
      )}
      <ToastContainer position="top-center" style={{ textAlign: "center" }} />
    </>
  );
};

export default DetectOffline;
