import { action, computed, thunk, thunkOn } from "easy-peasy";
import { toast } from "react-toastify";
import API, { APIRoutes } from "../../services/api";
import { ImWarning } from "react-icons/im";
import { BiErrorAlt } from "react-icons/bi";

const bookingModel = {
  carts: [],
  addToCart: action((state, cart) => {
    if (state.carts.id !== cart.id) {
      state.carts.push(cart);
      toast.success(
        () => (
          <strong style={{ display: "block", textAlign: "center" }}>
            ✔ Le panneau à bien été ajouté.
          </strong>
        ),
        {
          autoClose: 4000,
        }
      );
    }
  }),

  clearCurrentCart: action((state) => {
    state.carts = [];
  }),

  cartExist: computed(
    (state) => (id) => state.carts.some((cart) => cart.id === id)
  ),

  removeCart: action((state, cartRemove) => {
    const newCart = state.carts.filter((cart) => cart.id !== cartRemove.id);
    state.carts = newCart;

    if (state.carts.id === cartRemove.id) {
      toast.warning(
        <strong>
          <ImWarning style={{ marginTop: "-8px" }} /> Le panneau est déjà
          ajouté.
        </strong>,
        {
          position: "top-center",
          autoClose: 5000,
          toastId: "invalid-booking-exist-form-error-toast-id",
        }
      );
    } else {
      toast.info(<strong>Le panneau à bien été supprimé.</strong>, {
        position: "top-center",
        autoClose: 4000,
      });
    }
  }),

  totalAmount: computed((state) => {
    let totalItems = 0;
    state.carts.forEach((cart) => {
      totalItems += parseFloat(cart.price);
    });
    return totalItems;
  }),

  numberOfDays: computed((_) => (date1, date2) => {
    var Diff_temps = date2.getTime() - date1.getTime();
    var Diff_jours = Diff_temps / (1000 * 3600 * 24);
    return Math.round(Diff_jours);
  }),

  getDaysArray: computed((_) => (start, end) => {
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  }),

  createBooking: thunk(async (_, booking) => {
    try {
      const { data } = await API.post(APIRoutes.createBooking, booking);

      return data.status;
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.error}
            </strong>
          ),
          {
            autoClose: false,
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  allBookingsByUsers: [],
  setAllBookingsByUsers: action((state, allBookingsByUsers) => {
    state.allBookingsByUsers = allBookingsByUsers;
  }),

  getAllBookingsByUsers: thunk(async (actions) => {
    const { data } = await API.get(APIRoutes.getAllBookingsByUsers);
    actions.setAllBookingsByUsers(data);
  }),

  editBookingBillboard: thunk(async (_, { id, donne }, helpers) => {
    try {
      const { data } = await API.patch(
        APIRoutes.editBookingBillboard({ id }),
        donne
      );
      helpers.getStoreActions().bookings.getAllBookingsByUsers();
      toast.success(
        <strong>✔ Le statut du panneau à bien été modifé.</strong>,
        {
          position: "top-center",
          autoClose: 4000,
        }
      );
      return data;
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.error}
            </strong>
          ),
          {
            autoClose: false,
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  viewInfo: 0,
  quickViewInfo: action((state, viewInfo) => {
    state.viewInfo = viewInfo;
  }),

  viewDetail: 0,
  quickViewDetail: action((state, viewDetail) => {
    state.viewDetail = viewDetail;
  }),

  view: 0,
  quickView: action((state, view) => {
    state.view = view;
  }),

  onLogoutUser: thunkOn(
    (_, storeActions) => storeActions.auth.logoutUser,
    (actions) => {
      actions.setAllBookingsByUsers([]);
      actions.quickView(0);
      actions.quickViewInfo(0);
      actions.clearCurrentCart();
    }
  ),
};
export default bookingModel;
