import { action, computed, thunk, thunkOn } from "easy-peasy";
import { toast } from "react-toastify";
import API, { APIRoutes } from "../../services/api";
import { BiErrorAlt } from "react-icons/bi";

export const billboardModel = {
  isGridView: true,
  isCurrentBillboard: {},

  setGridView: action((state, { isGridView, data }) => {
    state.isGridView = isGridView;
    state.isCurrentBillboard = data;
  }),

  billboards: [],
  setBillboards: action((state, newBillboards) => {
    state.billboards = newBillboards;
  }),

  getBillboards: thunk(async (actions) => {
    const { data } = await API.get(APIRoutes.getBillboards);
    actions.setBillboards(data);
  }),

  allBillboardsByUsers: [],
  setAllBillboardsByUsers: action((state, allBillboardsByUsers) => {
    state.allBillboardsByUsers = allBillboardsByUsers;
  }),

  getAllBillboardsByUsers: thunk(async (actions) => {
    const { data } = await API.get(APIRoutes.getAllBillboardsByUsers);
    actions.setAllBillboardsByUsers(data);
  }),

  userBillboards: computed(
    (state) => (userId) =>
      state.billboards.filter((board) => board.profile_id === userId)
  ),

  view: 0,
  quickView: action((state, view) => {
    state.view = view;
  }),

  viewApprove: 0,
  quickViewApprove: action((state, view) => {
    state.viewApprove = view;
  }),

  viewDelete: 0,
  quickViewDelete: action((state, view) => {
    state.viewDelete = view;
  }),

  formatPrice: computed(
    (_) => (price) =>
      String(price).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ") + " GNF"
  ),

  formatDate: computed(
    (_) => (timestamp) => new Date(timestamp).toUTCString().split(", ")[1]
  ),

  //Récuperer la date actuel au format "2021-07-16"
  formatCurrentDate: computed((_) => (d = new Date()) => {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${year}-${month}-${day}`;
  }),

  addNewBillboards: thunk(async (_, billboard) => {
    try {
      const { data } = await API.post(APIRoutes.createBillboard, billboard);
      toast.success(
        <strong style={{ fontSize: "14px" }}>
          ✔ Le panneau à bien été sauvegardé.
        </strong>,
        {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      return data;
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.error}
            </strong>
          ),
          {
            autoClose: false,
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  editBillboard: thunk(async (_, { id, formData }) => {
    try {
      const { data } = await API.patch(
        APIRoutes.editBillboard({ id }),
        formData
      );
      toast.success(
        <strong style={{ fontSize: "14px" }}>
          ✔ Le panneau à bien été modifié.
        </strong>,
        {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      return data;
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.error}
            </strong>
          ),
          {
            autoClose: false,
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  detailBillboardRegie: thunk(async (_, { id }) => {
    try {
      const { data } = await API.get(APIRoutes.detailBillboardRegie({ id }));
      return data;
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.detail}
            </strong>
          ),
          {
            autoClose: false,
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  detailBillboardPublic: thunk(async (_, { id }) => {
    try {
      const { data } = await API.get(APIRoutes.detailBillboardPublic({ id }));
      return data;
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.detail}
            </strong>
          ),
          {
            autoClose: false,
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  deleteBillboard: thunk(async (_, { id }, helpers) => {
    try {
      await API.delete(APIRoutes.deleteBillboard({ id }));
      helpers.getStoreActions().billboards.getAllBillboardsByUsers();
      toast.error(<strong>Le panneau à bien été supprimé.</strong>, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.error}
            </strong>
          ),
          {
            autoClose: false,
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
      return error;
    }
  }),

  onLogoutUser: thunkOn(
    (_, storeActions) => storeActions.auth.logoutUser,
    (actions) => {
      //actions.setBillboards([]);
      actions.setAllBillboardsByUsers([]);
      actions.quickView(0);
    }
  ),

  //empecher à user connecté d'editer un panneau qui l'appartient pas
  billboardAddByUserExist: computed(
    (state) => (id) =>
      state.allBillboardsByUsers.some((billboard) => billboard.id == id)
  ),

  billboardIdExist: computed(
    (state) => (id) => state.billboards.some((billboard) => billboard.id == id)
  ),

  approveBillboard: thunk(async (_, { id, donne }, helpers) => {
    try {
      const { data } = await API.patch(APIRoutes.approveBillboard({ id }), donne);
      helpers.getStoreActions().billboards.getAllBillboardsByUsers();
      return data.visible;
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.detail}
            </strong>
          ),
          {
            autoClose: false,
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),
};
