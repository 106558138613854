import { action } from "easy-peasy";

const localConfigModel = {
  isNetworkOffline: false,
  setNetworkStatus: action((state, newStatus) => {
    state.isNetworkOffline = newStatus;
  }),

  isOfflineAlertShowed: false,
  setOfflineAlertShowed: action((state, newStatus) => {
    state.isOfflineAlertShowed = newStatus;
  }),
};

export default localConfigModel;
