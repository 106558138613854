export const appRoutes = {
  home: "/",
  login: "/login",
  register: "/register",
  createProfile: "/create-profile",
  profile: "/profile",
  panier: "/panier",
  checkout: "/checkout",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  contacts: "/contacts",

  //Backend regie
  homeBackend: "/backend/home",
  listBillboard: "/backend/list-billboard",
  createBillboard: "/backend/create-billboard",
  editBillboard: "/backend/edit-billboard",
  bookingBillboard: "/backend/booking-billboard",

  //Admin
  homeAdmin: "/admin/home",
  listBillboardAdmin: "/admin/list-billboard",
  allBookings: "/admin/all-bookings",
};

export const defaultRouteAfterLogin = appRoutes.home;
