import { action, thunk, computed } from "easy-peasy";
import API, { APIRoutes } from "../../services/api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { BiErrorAlt } from "react-icons/bi";

const userModel = {
  currentUser: {},
  setCurrentUser: action((state, data) => {
    state.currentUser = data;
  }),

  stepOnePassedRegister: false,
  setStepOnePassedRegister: action((state, isPassedRegister) => {
    state.stepOnePassedRegister = isPassedRegister;
  }),

  /*stepOnePassedForgotPassword: false,
  setStepOnePassedForgotPassword: action((state, isPassedForgotPassword) => {
    state.stepOnePassedForgotPassword = isPassedForgotPassword;
  }),*/

  loadUserFromCookies: thunk(async (actions, userToken) => {
    API.defaults.headers.Authorization = `REGITOR ${userToken}`;
  }),

  forgotPassword: thunk(async (actions, email) => {
    try {
      await API.post(APIRoutes.forgotPassword, email);
      //actions.setStepOnePassedForgotPassword(true)
      toast.success(
        () => (
          <strong style={{ display: "block", textAlign: "center" }}>
            ✔ Code de réinitialisation envoyé à votre e-mail.
          </strong>
        ),
        {
          autoClose: false,
        }
      );
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.email}
            </strong>
          ),
          {
            autoClose: false,
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  resetPassword: thunk(async (actions, pass) => {
    try {
      const { data } = await API.post(APIRoutes.resetPassword, pass);
      //actions.setStepOnePassedForgotPassword(false)
      toast.success(
        () => (
          <strong style={{ display: "block", textAlign: "center" }}>
            ✔ Mot de passe réinitialisé, connectez-vous avec votre nouveau mot
            de passe.
          </strong>
        ),
        {
          autoClose: false,
        }
      );
      //if updated password
      return data.status;
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.detail || error.response.data.password}
            </strong>
          ),
          {
            autoClose: false,
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  loginUser: thunk(async (actions, credentials) => {
    try {
      const { data } = await API.post(APIRoutes.userLogin, credentials);
      Cookies.set("access", data.user.access, { expires: 60 });
      API.defaults.headers.Authorization = `REGITOR ${data.user.access}`;

      actions.setCurrentUser(data.user);
      actions.setIsLogoutAttempt(false);
      return data.user.email;
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.detail}
            </strong>
          ),
          {
            autoClose: false,
            toastId: "login-error-toast-id",
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  dataUser: {},
  setDataUser: action((state, data) => {
    state.dataUser = data;
  }),

  fetchData: thunk(async (actions, userInfos) => {
    actions.setDataUser(userInfos);
    actions.setStepOnePassedRegister(true);
  }),

  registerUser: thunk(async (actions, userInfos) => {
    try {
      const { data } = await API.post(APIRoutes.userRegister, userInfos);

      Cookies.set("access", data.user.access, { expires: 60 });
      API.defaults.headers.Authorization = `REGITOR ${data.user.access}`;
      actions.setCurrentUser(data.user);

      actions.setDataUser({});
      actions.setStepOnePassedRegister(false);
      actions.setIsLogoutAttempt(false);
    } catch (error) {
      //if error on netoye
      actions.setDataUser({});
      actions.setStepOnePassedRegister(false);
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              Email déjà existant.
            </strong>
          ),
          {
            autoClose: false,
            toastId: "register-error-toast-id",
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  createProfile: thunk(async (actions, profile, helpers) => {
    try {
      //On copie les données du profile dans dataUser
      actions.setDataUser({
        user: { ...helpers.getState().dataUser, profile: profile },
      });
      //Puis on save user
      helpers.getStoreActions().auth.registerUser(helpers.getState().dataUser);
    } catch (error) {}
  }),

  editUser: thunk(async (actions, userInfo, helpers) => {
    try {
      const { data } = await API.put(APIRoutes.editUser, userInfo);
      //Pour avoir les données fraiches sans perdre les données qui non pas été édité
      actions.setCurrentUser({
        ...helpers.getState().currentUser,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,

        profile: {
          organisation: data.profile.organisation,
          adress: data.profile.adress,
          profile_type: data.profile.profile_type,
        },
      });

      toast.success(<strong>✔ Mis à jour éffectuée.</strong>, {
        position: "top-center",
        autoClose: 4000,
      });
    } catch (error) {
      if (error.response) {
        toast.error(
          () => (
            <strong style={{ display: "block", textAlign: "center" }}>
              <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
              {error.response.data.detail}
            </strong>
          ),
          {
            autoClose: false,
            toastId: "login-error-toast-id",
          }
        );
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  editPassword: thunk(async (_, { id, password }) => {
    try {
      const { data } = await API.put(APIRoutes.editPassword({ id }), password);

      toast.success(() => (
        <strong style={{ display: "block", textAlign: "center" }}>
          ✔ Votre mot de passe à bien été réinitialisé.
        </strong>
      ));
    } catch (error) {
      if (error.response) {
        toast.error(() => (
          <strong style={{ display: "block", textAlign: "center" }}>
            <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
            {error.response.data.detail}
          </strong>
        ));
      } else if (error.request) {
        toast.error(
          () => (
            <p style={{ padding: 10, paddingBottom: 0 }}>
              <strong>
                <BiErrorAlt style={{ fontSize: "20px", marginTop: "-6px" }} />{" "}
                Veuillez vérifier votre connexion internet puis réessayer.
              </strong>
            </p>
          ),
          {
            autoClose: false,
            toastId: "offline-request-error",
          }
        );
      }
    }
  }),

  isUserLoggedIn: computed(
    (state) => Object.keys(state.currentUser).length != 0
  ),

  isLogoutAttempt: false,
  setIsLogoutAttempt: action((state, newState) => {
    state.isLogoutAttempt = newState;
  }),

  logoutUser: thunk((actions, _, helpers) => {
    Cookies.remove("access");
    delete API.defaults.headers.Authorization;
    actions.setIsLogoutAttempt(true);
    actions.setCurrentUser({});
    actions.setDataUser({});
    actions.setStepOnePassedRegister(false);
  }),
};
export default userModel;
