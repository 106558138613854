import axios from "axios";
import axiosRetry from "axios-retry";

export const APIRoutes = {
  //AUTH
  userLogin: "/v1/auth/token",
  userInfos: (id) => `/v1/users/profiles/${id}`,
  userRegister: "/v1/auth/users/create",
  forgotPassword: "/v1/auth/password_reset/",
  resetPassword: "/v1/auth/password_reset/confirm/",
  editUser: "/v1/auth/users/update",
  editPassword: ({ id }) => `/v1/auth/change_password/${id}`,

  //BILLBOARDS
  createBillboard: "/v1/billboards",
  getAllBillboardsByUsers: "/v1/billboards/regie", //regie, Admin
  getBillboards: "/v1/billboards",
  detailBillboardRegie: ({ id }) => `v1/billboards/${id}`,
  detailBillboardPublic: ({ id }) => `v1/billboards/spectators/${id}`,
  editBillboard: ({ id }) => `/v1/billboards/${id}`,
  deleteBillboard: ({ id }) => `/v1/billboards/${id}`,
  approveBillboard: ({ id }) => `/v1/billboards/${id}`, //Admin, regie

  //BOOKINGS
  createBooking: "/v1/bookings",
  getAllBookingsByUsers: "/v1/bookings", //regie, Admin , Annonceur
  editBookingBillboard: ({ id }) => `/v1/bookings/orders/${id}`,
};

//https://api.regitorr.com/
//http://localhost:8000/

const baseURL = `https://api.regitorr.com/`;
// const baseURL = `http://192.168.43.29:8010/proxy`;

const API = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const retryDelay = (retryNumber = 0) => {
  const seconds = Math.pow(2, retryNumber) * 1000;
  const randomMs = 1000 * Math.random();
  return seconds + randomMs;
};

axiosRetry(API, {
  retries: 3,
  retryDelay,
  // retry on Network Error & 5xx responses
  retryCondition: axiosRetry.isRetryableError,
});

export default API;
