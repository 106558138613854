import React, { useEffect, useState } from "react";
import protectedRoutes from "../../configs/auth";
import { withRouter } from "next/router";
import { useStoreActions, useStoreRehydrated, useStoreState } from "easy-peasy";
import Loader from "../loader/Loader";
import Cookies from "js-cookie";
import { appRoutes } from "../../configs/routes";
import API from "../../services/api";
import client from "../../services/mqtt";
import { toast } from "react-toastify";

const ProtectRoute = ({ children, router }) => {
  const { currentUser } = useStoreState((state) => state.auth);
  const { getBillboards, getAllBillboardsByUsers } = useStoreActions(
    (actions) => actions.billboards
  );
  const { getAllBookingsByUsers } = useStoreActions(
    (actions) => actions.bookings
  );

  const [checking, setChecking] = useState(true);

  const isStoreRehydrated = useStoreRehydrated();

  const isUserLoggedIn = useStoreState((state) => state.auth.isUserLoggedIn);
  const isLogoutAttempt = useStoreState((state) => state.auth.isLogoutAttempt);

  const loadUserFromCookies = useStoreActions(
    (state) => state.auth.loadUserFromCookies
  );

  const token = Cookies.get("access");

  if (token /*&& !isUserLoggedIn*/ && !API.defaults.headers.Authorization) {
    loadUserFromCookies(token);
  }

  useEffect(() => {
    if (isUserLoggedIn) {
      //mqtt
      //Annonceur lance une commande
      client.subscribe("bookingAnnonceur");

      //Regie modifie une commande
      client.subscribe("bookingEditRegie");

      //Annonceur modifie une commande
      client.subscribe("bookingEditAnnonceur");

      //Notifier tous les annonceurs excepté celui qui à lancé la commande
      //client.subscribe(`user_${currentUser.profile.profile_type === 'annonceur'}`)

      client.subscribe("regieApproveBillboard");
      client.subscribe("regieDesapproveBillboard");

      client.on("message", function (topic, message) {
        //Actions: annonceur
        //Notifier au regie à qui appartient le panneau q'uil à été loué
        //rafraichir la page pour les autres users
        if (topic === "bookingAnnonceur") {
          const messageReceived = JSON.parse(message);
          if (messageReceived.idUser.includes(currentUser.id)) {
            getAllBillboardsByUsers();
            getBillboards();
            getAllBookingsByUsers();
            toast.success(<strong>{messageReceived.data}</strong>, {
              position: "top-center",
              autoClose: false,
            });
          } else {
            getBillboards();
          }
        }

        //Actions: regie
        //On notifie l'annonceur qui à lancé la commande qd un pano est modifié et rafraichir la page pour les autres users
        if (topic === "bookingEditRegie") {
          const messageReceived = JSON.parse(message);
          if (currentUser.id === messageReceived.idUser) {
            getAllBookingsByUsers();
            getBillboards();
            toast.success(<strong>{messageReceived.data}</strong>, {
              position: "top-center",
              autoClose: false,
            });
          } else {
            getBillboards();
          }
        }

        //Actions: annonceur
        //Notifier au regie propriétaire du panno quand la commande à été édité
        //rafraichir la page pour les autres users
        if (topic === "bookingEditAnnonceur") {
          const messageReceived = JSON.parse(message);
          if (currentUser.id === messageReceived.idUser) {
            getAllBillboardsByUsers();
            getBillboards();
            getAllBookingsByUsers();
            toast.success(<strong>{messageReceived.data}</strong>, {
              position: "top-center",
              autoClose: false,
            });
          } else {
            getBillboards();
          }
        }

        if (topic === "regieApproveBillboard") {
          const messageReceived = JSON.parse(message);
          if (currentUser.id === messageReceived.idUser) {
            getAllBillboardsByUsers();
            getBillboards();
            toast.success(<strong>{messageReceived.data}</strong>, {
              position: "top-center",
              autoClose: false,
            });
          } else {
            getBillboards();
          }
        }
        if (topic === "regieDesapproveBillboard") {
          const messageReceived = JSON.parse(message);
          if (currentUser.id === messageReceived.idUser) {
            getAllBillboardsByUsers();
            getBillboards();
            toast.success(<strong>{messageReceived.data}</strong>, {
              position: "top-center",
              autoClose: false,
            });
          } else {
            getBillboards();
          }
        }

        //Actions: annonceur
        //Notifier tous les annonceurs excepté celui qui à lancé la commande qu'un panno à été loué
        /*if(topic === `user_${currentUser.profile.profile_type === 'annonceur'}`)
        {
          const messageReceived = JSON.parse(message);
          //Si l'id de user actuel est different à celui qui à lancé la commande, affiche le msg
          if(currentUser.id !== messageReceived.idUser)
          {
            console.log("Notifie tous les annonceurs:", messageReceived);
          }
        }*/
      });
    }

    // Clean up subscriptions
    return () => {
      client.unsubscribe("bookingAnnonceur");
      client.unsubscribe("bookingEditRegie");
      client.unsubscribe("bookingEditAnnonceur");
      client.unsubscribe("regieApproveBillboard");
      client.unsubscribe("regieDesapproveBillboard");
    };
  }, []);

  useEffect(() => {
    getBillboards();
    const isProtectedRoute = !!protectedRoutes.find((r) => r === router.route);

    if (isProtectedRoute && !isUserLoggedIn && !isLogoutAttempt) {
      router
        .replace(
          {
            pathname: appRoutes.login,
            query: { next: router.pathname },
          },
          appRoutes.login
        )
        .then(() => {
          setChecking(true);
        });
    } else {
      setChecking(false);
      if (isUserLoggedIn) {
        getAllBookingsByUsers();

        if (
          currentUser?.profile?.profile_type == "Regie" ||
          currentUser?.profile?.profile_type == "Admin"
        ) {
          getAllBillboardsByUsers();
        }
      }
    }
  }, [router.route]);

  // return <>{checking || !isStoreRehydrated ? <Loader /> : children}</>;
  return <>{children}</>;
};

export default withRouter(ProtectRoute);
